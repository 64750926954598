<template>
      <div class="entry-content">
        <div class="container">
             <div class="entry-content-myaccount order-details">

                   <router-link
              to="/my-account/order_history"
              class="fill"
              ><i class="fas fa-long-arrow-alt-left mb-3" style="font-size:36px"></i></router-link
            >

             <h2>{{ $t('Order_history') }}</h2>

               <div class="row mb-4">
                            <div class="col-md-6">
                                     <h3 class="mb-4">{{ $t("Contact_info") }}</h3>
                                    <div class="box address">
                                        <ul>
                        <li>
                           
                          <i class="far fa-user-circle"></i
                          > {{ $root?.user ? order?.user?.name : order?.customer_name }}
                        </li>
                        <li>
                          <i class="fas fa-mobile-alt"></i>
                          {{
                              $root?.user ? order?.user?.mobile : order?.customer_mobile
                          }}
                        </li>
                      </ul>
                                    </div>
                                </div>
                                 <div class="col-md-6">
                                      <h3  class="mb-4">{{ $t('Shipping_place') }}</h3>
                                        <div class="box address">
                                          <ul>
                        <li>
                          {{ address.country_region }} - {{ address.city }} -
                          {{ address.street_address }} - {{ address.zip_code }} -
                          {{ address.state }}
                        </li>
                        <li>
                          {{ address?.notes }}
                        </li>
                      </ul>
                                        </div>
                                    </div>
                                    </div>
            <div class="row">
                        <div class="col-md-12">

                           
                               
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">{{$t('photo')}}</th>
                                            <th scope="col">{{$t('name')}}</th>
                                            <th scope="col">{{$t('quantity')}}</th>
                                            <th scope="col">{{$t('total_summation')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  :key="index"
                        v-for="(item, index) in items">
                                            <td>
                                                <figure class="mb-0"><img src="item.image" alt=""></figure>
                                            </td>
                                            <td >{{ item.item_name || item.name }}</td>
                                            <td>{{ item.item_quantity }}</td>
                                            <td>{{ item.item_price }} $</td>
                                        </tr>
                                    </tbody>
                                </table>
                              
                            </div>

                        </div>
                          <div class="row">
                            <div class="col-md-4 me-md-auto">
      <div class="entry-content checkout">
                         <div class="block left">
                   
                    <div class="footer">
                      <ul>
                        <li>
                          {{ $t("number_of_products") }}
                     
                         <span>{{
                             items
                             ? items.reduce(
                                 (c, n) => c + parseInt(n.item_quantity),
                                 0
                             )
                             : 0
                         }}</span>
                        </li>
                        <li>
                          {{ $t("total_summation") }}
                          <span>{{ totals.total }}$</span>
                        </li>
                        <li>
                          {{ $t("Discount") }} <span> {{ totals.discount }} $</span>
                        </li>
                        <li>
                          {{ $t("Delivery_price") }}
                          <span
                            v-if="totals.total > limit_shipment"
                            style="
                          float: left;
                          color: #9f9f9f;
                          text-decoration: line-through;
                          font-weight: 500;
                        "
                          >
                           {{ total_shipment.toFixed(2) }}
                            $
                          </span>

                          <span v-else> {{ total_shipment.toFixed(2) }} $</span>
                        </li>
                        <li>
                          {{ $t("tax total") }}

                          <span> + {{ parseFloat(total_taxes).toFixed(2) }} $</span>
                        </li>
                        <li class="toot">
                          {{ $t("total_summation") }}
                          <span v-if="totals.total_taxed > limit_shipment">
                            {{ totals.total_taxed.toFixed(2) }}
                            $</span
                          >
                          <span v-else>
                           {{
                               parseFloat(totals.total_taxed + total_shipment).toFixed(2)
                           }}
                            $</span
                          >
                        </li>
                      </ul>
                    </div>
                    <!-- <div class="footer">
                  <ul>
                    <li>رقم العملية <span>462758964660-8059078</span></li>
                    <li>رقم الطلب <span>462758964660-8059078</span></li>
                    <li>معرف الطلب <span>462758964660-8059078</span></li>
                  </ul>
                </div> -->
                  </div>
                      </div>
            </div>
            </div>
                    </div>
        </div>
    </div>


</template>
<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            ids: [],
            total_shipment: 0,
        };
    },
    mounted() {
console.log(this.$route.params.id)
        if (this.$route.params.id) {
            localStorage.removeItem("order");
            this.$store.dispatch("order/show", { id: this.$route.params.id });
            this.$store.dispatch("settings/index");
            this.$store.dispatch("order_item/index", {
                order_id: this.$route.params.id,
            });
            this.ids = this.items.map((v) => v.item_id);
            if (this.ids.length > 0) {
                this.$store.dispatch("shippinga/index", { ids: this.ids });
            }
        }
    },
    computed: {
        ...mapState({
            order: (state) => state.order.one,
            address: (state) => state.address.one,
            settings: (state) => state.setting.all || [],
            items: (state) => state.order_item.all?.data || [],
            shippingas: (state) => state.shippinga.all || [],
        }),

        totals() {
            let allTotlas = {
                total: 0,
                discount: 0,
                total_taxed: 0,
            };
            if (this.items) {
                let all_items = this.items;

                allTotlas.total = all_items.reduce(
                    (c, n) => c + n.item_price * n.item_quantity,
                    0
                );
                allTotlas.discount = all_items.reduce(
                    (c, n) => c + n.discount * n.item_quantity,
                    0
                );
                allTotlas.total_taxed = all_items.reduce((c, n) => {
                    let price =
                        n.item_price * n.item_quantity - n.discount * n.item_quantity;
                    let taxe = price * (n.tax_percentage / 100);
                    return (
                        c +
                        (n.item_price * n.item_quantity - n.discount * n.item_quantity) +
                        taxe
                    );
                }, 0);
            }
            return allTotlas;
        },
        limit_shipment() {
            var price = this.settings.find(
                (v) => v.key == "limit_price_for_shipment"
            )?.value;
            return parseFloat(price);
        },
        total_taxes() {
            if (this.items) {
                let all_items = this.items;
                return all_items.reduce((c, n) => {
                    let price =
                        n.item_price * n.item_quantity - n.discount * n.item_quantity;
                    let taxes = price * (n.tax_percentage / 100);
                    return c + taxes;
                }, 0);
            }
        },
    },
    watch: {
        order(val) {
            if (val) {
                this.$store.dispatch("address/show", { id: val.address_id });
            }
        },
        items(val) {
            if (val) {
                this.ids = val.map((v) => v.item_id);
                if (this.ids.length > 0) {
                    this.$store.dispatch("shippinga/index", { ids: this.ids });
                }
            }
        },
        shippingas(val) {
            if (val) {
                this.total_shipment = val.reduce((c, n) => c + parseFloat(n.price), 0);
            }
        },
        items(val) {
            this.ids = val.map((v) => v.item_id);
            if (this.ids.length > 0) {
                this.$store.dispatch("shippinga/index", { ids: this.ids });
            }
        },
    },
};
</script>