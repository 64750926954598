<template>
  <div class="container">
    <div class="entry-content checkout">
      <h2>{{ $t("Checkout") }}</h2>

      <div class="row">
        <div class="col-md-4">
          <div class="block">
            <h4>{{ $t("Order_information") }}</h4>
            <div class="orders">
              <div
                class="order-item"
                v-for="item in order.items"
                :key="item.id"
              >
                <div class="d-flex">
                  <a @click="remove(item)" class="del"
                    ><img src="@/main/assets/images/del.svg" alt=""
                  /></a>
                  <figure>
                    <img :src="item.image" alt="" />
                  </figure>
                  <div class="caption">
                    <h2>{{ item.name }}</h2>
                    <div class="d-flex justify-content-between">
                      <div class="price">
                        {{ item.item_price - (item.discount/100 * item.item_price)  }}
                        $ <br />
                        <span
                          v-if="item.discount > 0"
                          style="
                            float: left;
                            color: #9f9f9f;
                            text-decoration: line-through;
                            font-weight: 500;
                          "
                        >
                          {{ item.item_price  }}
                          $
                        </span>
                      </div>
                      <div class="quantity d-flex align-items-center">
                        <div id="quantity" class="d-flex align-items-center">
                          <button
                            @click="decrement(item)"
                            class="btn-subtract"
                            type="button"
                          >
                            -
                          </button>
                          <input
                            type="number"
                            class="item-quantity"
                            min="0"
                            :value="item.item_quantity"
                          />
                          <button
                            @click="increment(item)"
                            class="btn-add"
                            type="button"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form action="" class="copon" @submit.prevent="addCoupon">
              <input
                type="text"
                :placeholder="$t('Enter_the_coupon_code')"
                v-model="item.coupon"
              />
              <button>{{ $t("Activate_the_coupon") }}</button>
            </form>
            <div class="footer">
              <ul>
                <li>
                  {{ $t("number_of_products") }}
                  <span>{{
                    order.items
                      ? order.items.reduce(
                          (c, n) => c + parseInt(n.item_quantity),
                          0
                        )
                      : 0
                  }}</span>
                </li>
                <li>
                  {{ $t("total_summation") }}
                  <span>
                    {{ totals.total }}
                    $</span
                  >
                </li>
                <li>
                  {{ $t("Discount") }}
                  <span>
                    {{ totals.discount }}
                    $</span
                  >
                </li>
                <li>
                  {{ $t("Delivery_price") }}
                  <span
                    v-if="totals.total > limit_shipment"
                    style="
                      float: left;
                      color: #9f9f9f;
                      text-decoration: line-through;
                      font-weight: 500;
                    "
                  >
                    {{ total_shipment.toFixed(2) }}
                    $
                  </span>

                  <span v-else> {{ total_shipment.toFixed(2) }} $</span>
                </li>
                <li>
                  {{ $t("tax_total") }}

                  <span> + {{ parseFloat(total_taxes).toFixed(2) }} $</span>
                </li>
                <li class="toot">
                  {{ $t("total_summation") }}
                  <span v-if="totals.total_taxed > limit_shipment">
                    {{ totals.total_taxed.toFixed(2) }}
                    $</span
                  >
                  <span v-else>
                    {{
                      parseFloat(totals.total_taxed + total_shipment).toFixed(2)
                    }}
                    $</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="block">
            <h4>{{ $t("Delivery_details") }}</h4>
            <div class="row justify-content-center">
              <div class="col-md-11">
                <div class="content">
                  <div style="display: flex; justify-content: space-around">
                    <p v-if="$root.user">
                      {{ $t("register_as") }} :
                      <strong>{{ $root.user.name }}</strong>
                    </p>
                    <p v-if="$root.user">
                      {{ $t("your email") }} :
                      <strong>{{ $root.user.email }}</strong>
                    </p>
                    <p v-if="$root.user">
                      {{ $t("your phone number") }} :
                      <strong>{{ $root.user.mobile }}</strong>
                    </p>
                  </div>

                  <div class="box">
                    <div
                      v-if="$root.user"
                      class="entry-content-myaccount address"
                    >
                      <h2>{{ $t("my_addresses") }}</h2>
                      <div class="items">
                        <div
                          class="box address"
                          v-for="address in addresses"
                          :key="address.id"
                        >
                          <div class="d-flex">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                @click="validateAddress"
                                v-model="address.checked"
                                @input="checkAddress(address)"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckDefault"
                              >
                              </label>
                            </div>
                            <div>
                              <ul>
                                <li>
                                  {{ address.street_address }} -
                                  {{ address.apt_suit_building }} -
                                  {{ address.zip_code }} - {{ address.city }} -
                                  {{ address.country_region }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a
                        href="/my-account/my_addresses"
                        class="button online"
                        >{{ $t("add_site_new") }}</a
                      >
                    </div>
                    <div v-else class="entry-content-myaccount address">
                      <h2>{{ $t("complete_order_quest") }}</h2>
                      <form @submit.prevent="saveAddress(address)" class="form">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="mb-3">
                              <label
                                >{{ $t("first_name") }} <span>*</span></label
                              >
                              <input
                                type="text"
                                required
                                v-model="address.first_name"
                                class="form-control"
                                :placeholder="$t('first_name')"
                              />
                            </div>
                            <div>
                              <label
                                >{{ $t("last_name") }} <span>*</span></label
                              >
                              <input
                                type="text"
                                required
                                v-model="address.last_name"
                                class="form-control"
                                :placeholder="$t('last_name')"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div>
                                <label>{{ $t("email") }} <span>*</span></label>
                                <input
                                  type="email"
                                  v-model="address.email"
                                  class="form-control"
                                  :placeholder="$t('email')"
                                />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="mb-3">
                                <label
                                  >{{ $t("phone_number") }}
                                  <span>*</span></label
                                >
                                <input
                                  type="number"
                                  required
                                  v-model="address.phone_number"
                                  class="form-control"
                                  :placeholder="$t('phone_number')"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label
                              >{{ $t("street_address") }} <span>*</span></label
                            >
                            <input
                              type="text"
                              required
                              v-model="address.street_address"
                              class="form-control"
                              :placeholder="$t('street_address')"
                            />
                          </div>

                          <div class="mb-3">
                            <label
                              >Apt, Suit, Building ({{ $t("optional") }})<span
                                >*</span
                              ></label
                            >
                            <input
                              type="text"
                              v-model="address.apt_suit_building"
                              class="form-control"
                              placeholder="apt suit building"
                            />
                          </div>

                          <div class="col-md-6">
                            <div class="mb-3">
                              <label>Zip Code <span>*</span></label>
                              <input
                                type="text"
                                required
                                v-model="address.zip_code"
                                class="form-control"
                                placeholder="Zip Code"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label>{{ $t("city") }} <span>*</span></label>
                              <input
                                type="text"
                                required
                                v-model="address.city"
                                class="form-control"
                                :placeholder="$t('city')"
                              />
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="mb-3">
                              <label
                                >{{ $t("country_region") }}
                                <span>*</span></label
                              >
                              <input
                                type="text"
                                required
                                v-model="address.country_region"
                                class="form-control"
                                :placeholder="$t('country_region')"
                              />
                            </div>
                          </div>
                        </div>

                        <br />

                        <button type="submit" class="button">
                          {{ $t("add_site") }}
                        </button>
                      </form>
                      <div style="text-align: center" v-if="localeAddresses[0]">
                        <h4>{{ $t("choose an address") }}</h4>
                      </div>
                      <div
                        :key="index"
                        v-for="(address, index) in localeAddresses"
                        class="d-flex"
                      >
                         
                        <input
                          class="form-check-input"
                          id="flexCheckDefault"
                          type="radio"
                          @input="() => (selectedLocalAddress = address)"
                          name="fav_language"
                        />
                         

                        <label for="css">
                          <ul>
                            <li>
                              {{ address.street_address }} -
                              {{ address.apt_suit_building }} -
                              {{ address.zip_code }} - {{ address.city }} -
                              {{ address.country_region }}
                              <a
                                style="cursor: pointer"
                                @click="removeLocalAddress(index)"
                                ><i class="fas fa-times"></i
                              ></a>
                            </li>
                            <!-- <li>
                              {{ address.notes }}
                            </li> -->
                          </ul></label
                        ><br />
                      </div>
                    </div>
                    <div class="pay-box form mb-0">
                      <!--                       
                      <h6>اختار طريقة الشحن</h6> -->
                      <!-- <div  v-if="!validated">
                      <h5 style="text-align:center">{{$t('address_selected')}}</h5>

                      </div> -->
                      <!-- <form @submit.prevent="save">
                        <button class="button">
                          {{ $t("Complete_the_order") }}
                        </button>
                      </form>
                      <p>{{ $t("agree_condision") }}</p> -->
                    </div>
                  </div>

  <form @submit.prevent="save">
                    <div class="box">
                      <div class="entry-content-myaccount address pb-5">
                      <h2>{{ $t("PAYMENT") }}</h2>
                      <div class="form pt-0 pb-4">
                        <div class="row">
                          <div class="col-md-12">
                              <label
                                  >{{ $t("CARD_NUMBER") }} <span>*</span></label
                                >
                              <input
                                type="number"
                                required
                                v-model="payment.card_number"
                                class="form-control"
                                :placeholder="$t('CARD_NUMBER')"
                              />
                          
                          </div>
                        </div>

                          <div class="row">
                              <div class="col-md-3">
                                <label
                                  >{{ $t("EXPIRY_MONTH") }} <span>*</span></label
                                >

                                <select  v-model="payment.expiry_month"  required class="form-select"
                                    :placeholder="$t('EXPIRY_MONTH')">
    <option value="01">01</option>
    <option value="02">02</option>
    <option value="03">03</option>
    <option value="04">04</option>
    <option value="05">05</option>
    <option value="06">06</option>
    <option value="07">07</option>
    <option value="08">08</option>
    <option value="09">09</option>
    <option value="10">10</option>
    <option value="11">11</option>
        <option value="12">12</option>
  </select> 

                              
                              </div>
                            <div class="col-md-3">
                                <label>{{ $t("EXPIRY_YEAR") }} <span>*</span></label>
                                 <select  v-model="payment.expiry_year"  required class="form-select"
                                      :placeholder="$t('EXPIRY_YEAR')">
                                          <option v-for="item in yearRange" :value="item" >{{ item }}</option>

    </select> 
                              
                            </div>
                            <div class="col-md-6">
                                <label
                                  >{{ $t("CVV") }}
                                  <span>*</span></label
                                >
                                <input
                                  type="number"
                                  required
                                  v-model="payment.cvv"
                                  class="form-control"
                                  :placeholder="$t('CVV')"
                                />
                            </div>
                          </div>

                      </div>
                    </div>
                      <div class="pay-box form">
                        
                          <button class="button">
                            {{ $t("Complete_the_order") }}
                          </button>
                        
                        <p>{{ $t("agree_condision") }}</p>
                      </div>
                    </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center infoo">
        <div class="col-md-10 text-center">
          <p>
            {{ $t("security_text") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  mounted() {
    if (this.$root.user) {
      this.$store.dispatch("address/index", { user_id: this.$root.user.id });
    } else {
      if (localStorage.getItem("address")) {
        this.localeAddresses = JSON.parse(localStorage.getItem("address"));
      }
    }
    this.ids = this.order.items.map((v) => v.item_id);
    if (this.ids.length > 0) {
      this.$store.dispatch("shippinga/index", { ids: this.ids });
    }
    // this.$store.dispatch("shipping/index");
    // if (this.$attrs.id) {
    //   this.$store.dispatch("order/show", { id: this.$attrs.id });
    // } else {
    //   this.$router.push("/");
    // }
  },
  data() {
    return {
      selectedLocalAddress: null,
      localeAddresses: [],
      add: null,
      address: {},
      gust_order: {},
      selected_shipment: null,
      valid_selected_shipment: false,
      total_shipment: 0,
      my_address: null,
      ids: [],
      item: {
        shipment_price: 0,
        discount: 0,
        total: 0,
        item_quantity: 1,
      },
      payment: {},
    };
  },
  methods: {
    validateAddress() {
      document.getElementsById("dw").style.display = "none";
    },
    remove(item) {
      this.$store.dispatch("cart/removeItem", item);
    },
    checkedLocale(index) {
      // console.log(index);
      this.localeAddresses.map((v, i) => {
        // console.log(i);
        return i == index ? (v.checked = true) : (v.checked = false);
      });
    },
    increment(item) {
      this.$store.dispatch("cart/incrementItem", item);
    },
    decrement(item) {
      this.$store.dispatch("cart/decrementItem", item);
    },
    getTime() {
      var date = new Date();
      return date.toISOString().slice(0, 19).replace("T", " ");
    },
    async save() {
      if (this.$root.user) {
        let order_copy = JSON.parse(JSON.stringify(this.order));
        delete order_copy.items;

        order_copy.due_date = this.getTime();
        order_copy.status = 0;
        order_copy.taxed_total = this.totals.total_taxed;
        order_copy.discount = this.totals.discount;
        order_copy.total = this.totals.total;
        order_copy.address_id = this.my_address;
        order_copy.status = 3;


        order_copy.customer_email = this.$root?.user?.email;
        order_copy.customer_first_name = this.$root?.user?.name;
        order_copy.customer_last_name = this.$root?.user?.name;
        order_copy.customer_mobile =  this.$root?.user?.mobile;
        order_copy.customer_name = this.$root?.user?.user_name;
       
        if (this.totals.total_taxed < this.limit_shipment) {
          order_copy.taxed_total += this.total_shipment;
          order_copy.total_shipping = this.total_shipment;
        }

        if (!this.my_address) {
          this.$swal.fire({
            title: this.$t("error"),
            text: "choose an address",
            icon: "error",
            confirmButtonText: "ok",
            confirmButtonColor: "#41b882",
          });
          return;
        }

     let checkaddress =    this.addresses
          .find((i) => i.id ==  this.my_address)
        let payload = {
          "OrderId": order_copy?.id?.toString(),
          "amount": order_copy?.taxed_total?.toString(),
          "cardNumber": this.payment.card_number,
          "expirationDate": this.payment.expiry_year +"-"+ this.payment.expiry_month,
          "cardCode": this.payment.cvv,
          "lineItems": {
            "itemId": this.order?.items[0]?.item_id?.toString(),
            "name": this.order?.items[0]?.item_id?.toString(),
            "description": this.order?.items[0]?.description,
            "description": this.order?.items[0]?.item_id?.toString(),
            "quantity": this.order?.items[0]?.item_quantity?.toString(),
            "unitPrice": this.order?.items[0]?.item_price?.toString()
          },
          "taxAmount": order_copy?.taxed_total?.toString(),
          "taxName": "tax",
          "shippingAmount": order_copy?.total_shipping?.toString(),
          "shippingName": "shipping",
          "ContactNo": this.$root?.user?.mobile?.toString(),
          "customerId": this.$root?.user?.id?.toString(),
          "email": this.$root?.user?.email,
          "FirstName": checkaddress?.first_name,
          "lastName":  checkaddress?.last_name,
          "billingAddress": {
            "company": this.$root?.user?.user_name,
            "AddressLine1": checkaddress?.street_address,
            "City": checkaddress?.city,
            "State": checkaddress?.apt_suit_building,
            "Zipcode": checkaddress?.zip_code,
            "Country": checkaddress?.country_region,
          },
          "shippingAddress": {
            "company": this.$root?.user?.user_name,
            "AddressLine1": checkaddress?.street_address,
            "City": checkaddress?.city,
            "State": checkaddress?.apt_suit_building,
            "Zipcode": checkaddress?.zip_code,
            "Country": checkaddress?.country_region,
          },
          "ip": "192.168.1.7"
        }
          console.log(
          payload
        )
        
        let loader = this.$loading.show({
          canCancel: false, // default false
          color: "#7c4707",
          loader: "spinner",
          width: 64,
          height: 64,
          backgroundColor: "#ffffff",
          opacity: 0.7,
          zIndex: 999,

        });
        await this.$store.dispatch("authorizepayment/index", payload).then(async (data) => {
          console.log(data, data?.data?.messages)
          if(data?.data?.messages?.resultCode == "Ok") {
          await this.$store.dispatch("order/store", order_copy).then(() => {
            this.order.items.map((item) => {
              this.$store.dispatch("order_item/store", {
                ...item,
                order_id: this.order.id,
              });
            });
               let domain = `${process.env.URL || window.location.protocol}//${
                window.location.host
              }`;
              window.open(`${domain}/seccessful-payment/${this.order.id}`);
            loader.hide();
          }).catch((err) => {
            console.log(err)
            loader.hide();
             window.open(`${domain}/error-payment/${this.order.id}`);
          });
          }
          else {
            loader.hide();
              window.open(`${domain}/error-payment/${this.order.id}`);
          }
        }).catch((err) => { 
          loader.hide();
          window.open(`${domain}/error-payment/${this.order.id}`);

        })
         
   
        //   let domain = `${process.env.URL || window.location.protocol}//${
        //     window.location.host
        //   }`;
        //   window.open(`${domain}/complete-order/${this.order.id}`);
        //   this.$router.push("/cart");
        //   location.reload();
        // });
      } else {
        // if (
        //   !(
        //     this.gust_order.customer_email &&
        //     this.gust_order.customer_last_name &&
        //     this.gust_order.customer_first_name &&
        //     this.gust_order.customer_mobile
        //   )
        // ) {
        //    this.$swal.fire({
        //   title: this.$t('error'),
        //   text: "complete the fields ",
        //   icon: "error",
        //   confirmButtonText: "ok",
        //   confirmButtonColor: "#41b882",
        // });
        //   return;
        // };
        if (!this.selectedLocalAddress) {
          this.$swal.fire({
            title: this.$t("error"),
            text: "choose an address",
            icon: "error",
            confirmButtonText: "ok",
            confirmButtonColor: "#41b882",
          });
          return;
        }

        let order = { ...this.gust_order };
        order.due_date = this.getTime();
        order.issue_date = this.getTime();
        order.status = 0;
        order.taxed_total = this.totals.total_taxed;
        order.discount = this.totals.discount;
        order.total = this.totals.total;
        if (this.totals.total_taxed < this.limit_shipment) {
          order.taxed_total += this.total_shipment;
          order.total_shipping = this.total_shipment;
        }
         localStorage.removeItem("order");
        console.log(this.selectedLocalAddress, this.totals);
        let newaddress = await this.$store.dispatch(
          "address/store",
          this.selectedLocalAddress
        );

        if (newaddress) {
          order.address_id = newaddress.id;
          order.customer_email = this.selectedLocalAddress.email;
          order.customer_name = this.selectedLocalAddress.first_name;
          order.customer_mobile = this.selectedLocalAddress.phone_number;
          await this.$store.dispatch("order/store", order).then((new_order) => {
            this.order.items.map((item) => {
              this.$store
                .dispatch("order_item/store", {
                  ...item,
                  order_id: new_order.id,
                });
                
                  let domain = `${
                    process.env.URL || window.location.protocol
                  }//${window.location.host}`;
                  this.$router.push("/cart");
                  location.reload();
                  window.location = `${domain}/seccessful-payment/${new_order.id}`;
                
               
            });
          });
        }
      }
      // if (order_id) {

      // }
    },
    removeLocalAddress(index) {
      let addresses = JSON.parse(localStorage.getItem("address"));
      addresses.splice(index, 1);
      localStorage.setItem("address", JSON.stringify(addresses));
      this.localeAddresses = JSON.parse(localStorage.getItem("address"));
    },
    saveAddress(item) {
      // console.log(this.gust_order.customer_email);
      const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };
      const email = item.email;

      if (validateEmail(email ?? "invalid")) {
        if (localStorage.getItem("address")) {
          let addresses = JSON.parse(localStorage.getItem("address"));
          addresses.push(item);
          localStorage.setItem("address", JSON.stringify(addresses));
        } else {
          localStorage.setItem("address", JSON.stringify([item]));
        }
        this.localeAddresses = JSON.parse(localStorage.getItem("address"));
      } else {
        this.$swal.fire({
          title: this.$t("error"),
          text: this.$t("email_error"),
          icon: "error",
          confirmButtonText: "تم",
          confirmButtonColor: "#41b882",
        });
      }
    },

    addCoupon() {
      this.$store
        .dispatch("coupon/show", { id: this.item.coupon })
        .then((data) => {
          this.$store.dispatch("cart/setDiscountCoupon", data.value);
          this.$swal.fire({
            title: this.$t("success"),
            text: this.$t("success"),
            icon: "success",
            confirmButtonText: this.$t("Ok"),
            confirmButtonColor: "#41b882",
          });
        })
        .catch((err) => {
          this.$swal.fire({
            title: this.$t("error"),
            text: this.$t("invalid code"),
            icon: "error",
            confirmButtonText: "تم",
            confirmButtonColor: "#41b882",
          });
        });
    },
    checkAddress(address) {
      this.my_address = address.id;
      this.addresses
        .filter((i) => i.id != address.id)
        .map((i) => (i.checked = false));
    },
  },
  computed: {
    ...mapState({
      order: (state) => state.cart.order,
      addresses: (state) => state.address.all,
      settings: (state) => state.setting.all || [],
      // shipments: (state) => state.shipping.all || [],
      shippingas: (state) => state.shippinga.all || [],
    }),
    yearRange() {
      const currentYear = (new Date()).getFullYear();
      const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
      return  range(currentYear, currentYear + 25, 1)
    },
    validated() {
      if (this.$root.user) {
        if (this.my_address && this.totals.total_taxed > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          Object.keys(this.gust_order).length > 2 &&
          this.selectedLocalAddress
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    totals() {
      let allTotlas = {
        total: 0,
        discount: 0,
        total_taxed: 0,
      };
      if (this.order.items) {
        let all_items = this.order.items;

        allTotlas.total = all_items.reduce(
          (c, n) => c + n.item_price * n.item_quantity,
          0
        );
        allTotlas.discount = all_items.reduce(
          (c, n) => c + n.discount * n.item_quantity,
          0
        );
        allTotlas.total_taxed = all_items.reduce((c, n) => {
          let price =
            n.item_price * n.item_quantity - n.discount * n.item_quantity;
          let taxe = price * (n.tax_percentage / 100);
          return (
            c +
            (n.item_price * n.item_quantity - n.discount * n.item_quantity) +
            taxe
          );
        }, 0);
      }
      return allTotlas;
    },
    limit_shipment() {
      var price = this.settings.find(
        (v) => v.key == "limit_price_for_shipment"
      )?.value;
      return parseFloat(price);
    },
    shipment_price() {
      var price = this.settings.find((v) => v.key == "shipment_amount")?.value;
      return parseFloat(price);
    },
    total_taxes() {
      if (this.order.items) {
        let all_items = this.order.items;
        return all_items.reduce((c, n) => {
          let price =
            n.item_price * n.item_quantity - n.discount * n.item_quantity;
          let taxes = price * (n.tax_percentage / 100);
          return c + taxes;
        }, 0);
      }
    },
  },
  watch: {
    order: {
      handler(val) {
        if (val) {
          this.ids = val.items.map((v) => v.item_id);
          if (this.ids.length > 0) {
            this.$store.dispatch("shippinga/index", { ids: this.ids });
          }
        }
      },
      deep: true,
    },
    // selected_shipment(val) {
    //   if (val && this.ids) {
    //     this.$store.dispatch("shippinga/index", { ids: this.ids });
    //   }
    // },
    shippingas(val) {
      // if (val.length > 0) {
      //   if (val.length != this.order.items.length) {
      //     this.valid_selected_shipment = false;
      //     alert("cant choose this shipment method");
      //   } else {
      //     this.valid_selected_shipment = true;
      //     this.total_shipment = val.reduce((c, n) => c + n.price, 0);
      //   }
      // }
      if (val) {
        this.total_shipment = val.reduce((c, n) => c + parseFloat(n.price), 0);
      }
    },
  },
};
</script>
