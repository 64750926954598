<template>
  <div class="entry-content page-404 position-relative">
    <div style="margin-top: -300px" class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <form @submit.prevent="save()" class="forgot-form">
            <div class="row">
              <div class="col-md-12 mb-3">
                <label>{{ $t("Enter Your Email") }}</label>
                <br><br>
                <div class="input-group">
                  <span class="input-group-text"
                    ><i class="fas fa-envelope"></i
                  ></span>
                  <input
                    v-model="item.email"
                    type="email"
                    class="form-control"
                    :placeholder="$t('Enter Your Email')"
                @blur="validate('email')" 
                @keypress="validate('email')"
                  />
                </div>
                  <p class="errors" v-if="!!errors.email">{{ errors.email }}</p>
              </div>
            </div>
            <button style="color: white" type="submit" class="button">
              {{ $t("confirm") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Yup from "yup";
const forgotFormSchema = Yup.object().shape({
  email: Yup.string().email()
    .required("Email is required"),

});

export default {
  data() {
    return {
      item: { email: "" },
      errors: {
        email: ""
      }
    };
  },
  methods: {
    save() {
      let loader = this.$loading.show({
        canCancel: false, // default false
        color: "#7c4707",
        loader: "spinner",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.7,
        zIndex: 999,

      });
       forgotFormSchema
        .validate(this.item, { abortEarly: false })
        .then(() => {
          this.$store.dispatch("auth/sendResetLink", this.item).then((data) => {
            if (data.status) {
               loader.hide();
              this.$swal.fire({
                title: this.$t("success"),
                 text: data.data,
                icon: "success",
                confirmButtonText: this.$t("Ok"),
                confirmButtonColor: "#41b882",
              });
              this.$router.push("/");
            } else {
               loader.hide();
              this.$swal.fire({
                title: this.$t("error"),
                text: this.$t("login error"),
                icon: "error",
                confirmButtonText: this.$t("Ok"),
                confirmButtonColor: "#41b882",
              });
            }
          });
        })
         .catch(err => {
           loader.hide();
          err.inner.forEach(error => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
     validate(field) {
      forgotFormSchema.validateAt(field, this.item)
        .then(() => (this.errors[field] = ""))
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
  },
};
</script>

<style scoped>
.errors {
  color: red;
}
.forgot-form .form-control {
    box-shadow: none;
    border: 1px solid #ddd;
    border-radius: 0;
}
</style>