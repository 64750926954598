const state = {
  result: {},
};
const getters = {
  result: (state) => state.result,
};
const actions = {
  index({ commit }, data) {
    return this.$axios
      .post("/payment/authorizedotnet", data)
      .then((response) => {
        commit("setResult", response.data);
        return response.data;
      })
      .catch((err) => {
        // commit("setErrors", err);
        return err;
      });
  },
};
const mutations = {
  setResult: (state, data) => {
    state.result = data;
    // state.token = data.token;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
