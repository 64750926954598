<template>
  <div class="entry-content cart">
    <div class="head">
      <h2>{{ $t("Shopping_cart") }}</h2>
    </div>
    <div
      class="entry-content cart-empty"
      v-if="order.items ? order.items.length == 0 : true"
    >
      <div class="entry-content">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-4">
              <div class="text-center">
                <figure>
                  <img
                    src="@/main/assets/images/undraw_empty_cart_co35.svg"
                    alt=""
                  />
                </figure>
                <h3>{{ $t("cart_empty") }}</h3>
                <p>
                  {{ $t("go_to_option") }}
                </p>
                <a href="/main/category" class="button">
                  {{ $t("Continue_shopping") }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="entry-content" v-else>
      <div class="container">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="image">{{ $t("Photo") }}</th>
              <th scope="col">{{ $t("Product") }}</th>
              <th scope="col">{{ $t("Quantity") }}</th>
              <th scope="col">{{ $t("Total") }}</th>
              <th scope="col">{{ $t("delete") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in order.items" :key="item.id">
              <td data-title="">
                <a :href="`/main/single-product/${item.item_id}`">
                  <figure>
                    <img :src="item.image" alt="" />
                  </figure>
                </a>
              </td>
              <td>
                <a :href="`/main/single-product/${item.item_id}`">
                  {{ item.item_name || item.name }}</a
                >
              </td>
              <td :data-title="$t('quantity')">
                <div class="quantity d-flex align-items-center">
                  <div id="quantity" class="d-flex align-items-center">
                    <button
                      class="btn-subtract"
                      type="button"
                      @click="decrement(item)"
                    >
                      -
                    </button>
                    <input
                      type="number"
                      class="item-quantity"
                      min="0"
                      :value="item.item_quantity"
                    />
                    <button
                      class="btn-add"
                      type="button"
                      @click="increment(item)"
                    >
                      +
                    </button>
                  </div>
                </div>
              </td>
              <td>
                {{ calcTotal(item) }}
              </td>
              <td :data-title="$t('delete')">
                <a @click.prevent="remove(item)" class="button"
                  ><img src="@/main/assets/images/delete-red.svg" alt=""
                /></a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="6">
                <div class="d-flex justify-content-end">
                  <div class="d-flex">
                    <div class="totel me-3">
                      {{ $t("total_summation") }}:
                      <strong>
                        {{
                          order.items
                            .reduce((c, n) => c + calcTotal(n, true), 0)
                            .toFixed(2)
                        }}
                        $</strong
                      >
                    </div>
                    <div class="button0">
                      <router-link :to="`/checkout`">{{
                        $t("Complete_the_order")
                      }}</router-link>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },

  computed: {
    ...mapState({
      // order.items: (state) => state.cart.order.order.items,
      order: (state) => state.cart.order || {},
    }),
  },
  watch: {
    order(val) {
      if (!val.items) {
        this.$store.dispatch("cart/addOrderTotalPrice", 0);
      }
    },
  },
  methods: {
    increment(item) {
      this.$store.dispatch("cart/incrementItem", item);
    },
    decrement(item) {
      this.$store.dispatch("cart/decrementItem", item);
    },
    remove(item) {
      this.$store.dispatch("cart/removeItem", item);
    },
    calcPriceAfterDis(item) {
      return item.item_price * item.item_quantity - item.discount;
    },
    calcTotal(item, all) {
      let discount = item.discount * item.item_quantity;
      let item_dicounted = item.item_price * item.item_quantity - discount;
      // let tax = item.tax_percentage || 0;
      let total = item_dicounted;
      // let total = item_dicounted * (tax / 100 + 1);
      if (all) {
        return total;
      }
      return parseFloat(total).toFixed(2);
    },
  },
};
</script>

