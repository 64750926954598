<template>
  <div class="container">
    <div class="entry-content checkout">
      <h2>Account Verification</h2>

      <div class="row justify-content-center">
            <div class="col-md-12 p-0 text-center text-danger h4" v-if="error">
             {{ error }}
            </div>
              <div class="col-md-12 p-0 text-center h4" v-if="accountdata">
               {{ accountdata }}
              </div>
      </div>
      <div class="mt-5 mb-5 text-center">
        <a :href="retry()" class="button fill me-3"> {{ $t("Login") }} </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      error: "",
      accountdata:""
    };
  },
  mounted() {
    console.log(this.$route.params)
    if (this.$route.params.id) {
      this.$store.dispatch("auth/verfiyAccount", { token: this.$route.params.id }).then((data) => {
        if (data.status) {
          this.accountdata = data?.data
        }
      })
        .catch(err => {
            this.error = err
        });
    }
  },
  computed: {
    ...mapState({
      order: (state) => state.order.one,
    }),
 
  },
  watch: {
  },
  methods: {
    retry() {
      let domain = `${process.env.URL || window.location.protocol}//${
        window.location.host
      }`;
      return `${domain}/login`;
    },
  },
};
</script>


<style>
</style>