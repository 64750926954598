<template>
  <div class="bg-myacoutn">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <div class="form">
            <h2>{{ $t("welcome") }}</h2>
            <p>
              <small>{{ $t("create_account") }}</small>
            </p>
            <!-- <div v-for="(errorArray, idx) in notifmsg" :key="idx">
    <div v-for="(allErrors, idx) in errorArray" :key="idx">
        <span class="text-danger">{{ allErrors}} </span>
    </div>
</div> -->
            <form @submit.prevent="register()">
               <div :class="['input-group', !errors?.name && 'mb-3']">
                <span class="input-group-text"
                  ><i class="fas fa-user"></i
                ></span>
                <input
                
                type="text" 
                class="form-control" 
               :placeholder="$t('full_name')"
                 v-model="item.name"
                @blur="validate('name')" 
                @keypress="validate('name')"
                />
              </div>
               <p class="errors" v-if="!!errors.name">{{ errors.name }}</p>
              <div :class="['input-group', !errors?.user_name && 'mb-3']">
                <span class="input-group-text"
                  ><i class="fas fa-user"></i
                ></span>
                <input
                  v-model="item.user_name"
                   type="text" 
                  class="form-control" 
                 :placeholder="$t('enter_username')"
                  @blur="validate('user_name')" 
                  @keypress="validate('user_name')"
                />
              </div>
                <p class="errors" v-if="!!errors.user_name">{{ errors.user_name }}</p>
                 <div :class="['input-group', !errors?.mobile && 'mb-3']">
                <span class="input-group-text"
                  ><i class="fas fa-mobile-alt"></i
                ></span>
                 <input
                    v-model="item.mobile"
                     type="text" 
                    class="form-control" 
                   :placeholder="$t('mobile_no')"
                    @blur="validate('mobile')" 
                    @keypress="validate('mobile')"
                  />
              </div>
               <p class="errors" v-if="!!errors.mobile">{{ errors.mobile }}</p>
               <div :class="['input-group', !errors?.email && 'mb-3']">
                <span class="input-group-text"
                  ><i class="fas fa-envelope"></i
                ></span>
                <input
                  v-model="item.email"
                  type="email"
                  class="form-control"
                  :placeholder="$t('email')"
                  @blur="validate('email')" 
                  @keypress="validate('email')"
                />
              </div>
              <p class="errors" v-if="!!errors.email">{{ errors.email }}</p>
              <div :class="['input-group', !errors?.password && 'mb-3']">
                <span class="input-group-text"
                  ><i class="fas fa-lock"></i
                ></span>
                <input
                  v-model="item.password"
                  type="password"
                  class="form-control"
                  :placeholder="$t('password')"
                   @blur="validate('password')" 
                    @keypress="validate('password')"
                />
              </div>
               <p class="errors" v-if="!!errors.password">{{ errors.password }}</p>
              <div class="mb-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    required
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    {{ $t("agree") }}
                    <a href="/main/condition">{{ $t("Terms_of_use") }}</a>
                  </label>
                </div>
              </div>
              <button type="submit" class="button">
                {{ $t("create_account") }}
              </button>
            </form>
            <p class="text-center mt-3 mb-0">
              {{ $t("customer already") + "?" }}
              <a href="/main/login"
                ><strong>{{ $t("Login") }}</strong></a
              >
            </p>
          </div>
        </div>
        <div class="col-md-7">
          <div class="left">
            <p>
              {{ $t("new_customer_message") }}
            </p>
            <ul>
              <li>{{ $t("Track_your_orders") }}</li>
              <li>{{ $t("Automation_management") }}</li>
              <li>{{ $t("View_your_order_history") }}</li>
              <li>{{ $t("Rate_and_review_products") }}</li>
              <li>{{ $t("discount_and_more") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import * as Yup from "yup";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const registerFormSchema = Yup.object().shape({
  name : Yup.string().required("Name is required"),
  user_name: Yup.string().required("User Name is required"),
  mobile: Yup.string().matches(phoneRegExp, 'Mobile Number is not valid').required("Mobile Number is required"),
  email: Yup.string().email()
    .required("Email is required"),
  password: Yup.string().min(8, "Password should be less than 8 characters")
    .required("Password is required"),
});


export default {
  data() {
    return {
      item: {
        name : "",
        user_name : "",
        mobile : "",
        email: "",
        password: "",
      },
      errors: {
       name: "",
        user_name: "",
        mobile: "",
        email: "",
        password: "",
      },
       notifmsg: '',
    };
  },
  methods: {
    register() {
        let loader = this.$loading.show({
        canCancel: false, // default false
        color: "#7c4707",
        loader: "spinner",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.7,
        zIndex: 999,

      });
      registerFormSchema
        .validate(this.item, { abortEarly: false })
        .then(() => {
          this.$store.dispatch("auth/signup", this.item).then((data) => {
            if (data.status) {
                loader.hide();
              this.$swal.fire({
                title: this.$t("success"),
                // text: this.$t("login co"),
                text: data?.data,
                icon: "success",
                confirmButtonText: this.$t("Ok"),
                confirmButtonColor: "#41b882",
              });
              this.$router.push("/");
            } else {
                loader.hide();
              this.$swal.fire({
                title: this.$t("error"),
                text: this.$t('register_error'),
                icon: "error",
                confirmButtonText: this.$t("Ok"),
                confirmButtonColor: "#41b882",
              });
            }
          })
        })
        .catch(err => {
            loader.hide();
          err.inner.forEach(error => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
     },
     validate(field) {
      registerFormSchema.validateAt(field, this.item)
        .then(() => (this.errors[field] = ""))
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
  },
};
</script>
<style scoped>
.errors {
  color: red;
}
</style>