<template>
  <div class="entry-content page-404 position-relative">
    <div style="margin-top: -300px" class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <form @submit.prevent="save()" class="forgot-form">
            <div class="row">
              <div class="col-md-12">
                <label class="mb-2">{{ $t("Enter Your Password") }}</label>
                <div class="input-group">
                  <span class="input-group-text"
                    ><i class="fas fa-key"></i
                  ></span>
                  <input
                    v-model="item.password"
                    type="password"
                    class="form-control"
                    :placeholder="$t('Enter Your Password')"
                @blur="validate('password')" 
                @keypress="validate('password')"
                  />
                </div>
                  <p class="errors" v-if="!!errors.password">{{ errors.password }}</p>
              </div>
              <div class="col-md-12 mb-3">
                  <label class="mb-2">{{ $t("Enter Your Confirm Password") }}</label>
                  <div class="input-group">
                    <span class="input-group-text"
                      ><i class="fas fa-key"></i
                    ></span>
                    <input
                      v-model="item.confirmpassword"
                      type="password"
                      class="form-control"
                      :placeholder="$t('Enter Your Confirm Password')"
                  @blur="validate('confirmpassword')" 
                  @keypress="validate('confirmpassword')"
                    />
                  </div>
                    <p class="errors" v-if="!!errors.confirmpassword">{{ errors.confirmpassword }}</p>
                </div>
            </div>
            <button style="color: white" type="submit" class="button">
              {{ $t("confirm") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as Yup from "yup";
const forgotFormSchema = Yup.object().shape({
   password: Yup.string().min(8, "Password should be less than 8 characters").required('Password is required'),
  confirmpassword: Yup.string().min(8, "Confirm Password should be less than 8 characters")
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default {
  data() {
    return {
      item: { password: "", confirmpassword : "" },
      errors: {
        password: "",
        confirmpassword : ""
      }
    };
  },
  methods: {
    save() {
      let loader = this.$loading.show({
        canCancel: false, // default false
        color: "#7c4707",
        loader: "spinner",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.7,
        zIndex: 999,

      });
       forgotFormSchema
        .validate(this.item, { abortEarly: false })
         .then(() => {
            this.item.token = this.$route.params.id
           this.$store.dispatch("auth/resetPassword", this.item).then((data) => {
             if (data.status) {
               loader.hide();
              this.$swal.fire({
                title: this.$t("success"),
                 text: data.data,
                icon: "success",
                confirmButtonText: this.$t("Ok"),
                confirmButtonColor: "#41b882",
              });
              this.$router.push("/");
            } else {
               loader.hide();
              this.$swal.fire({
                title: this.$t("error"),
                text: data.data,
                icon: "error",
                confirmButtonText: this.$t("Ok"),
                confirmButtonColor: "#41b882",
              });
            }
          });
        })
         .catch(err => {
          console.log(err)
           loader.hide();
          err.inner.forEach(error => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
     validate(field) {
      forgotFormSchema.validateAt(field, this.item)
        .then(() => (this.errors[field] = ""))
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
      retry() {
        let domain = `${process.env.URL || window.location.protocol}//${window.location.host
          }`;
        return `${domain}/login`;
      },
  },
};
</script>

<style scoped>
.errors {
  color: red;
}
.forgot-form .form-control {
    box-shadow: none;
    border: 1px solid #ddd;
    border-radius: 0;
}
</style>